<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="locale in locales"
      :key="locale.locale"
      @click="change(locale.locale)"
    >
      <b-img
        :src="locale.img"
        height="14px"
        width="22px"
        :alt="locale.locale"
      />
      <span class="ml-50">{{ locale.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'hu',
        img: require('@/assets/images/flags/hu.png'),
        name: 'Magyar',
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
    ]
    /* eslint-disable global-require */
    return {
      locales,
    }
  },
  methods: {
    change(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
      // set validation locale
      localize(locale)
      // window.location.reload()
    },
  },
}
</script>

<style>

</style>
