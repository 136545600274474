<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <b-link
          v-if="layoutType === 'horizontal'"
          class="navbar-brand d-xl-block d-none"
          to="/"
      >
          <span class="brand-logo">
            <b-img
                v-if="skin !== 'dark'"
                :src="appLogoImageDark"
                alt="logo"
            />
                <b-img
                    v-else
                    :src="appLogoImageLight"
                    alt="logo"
                />
          </span>
      </b-link>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-toggler class="d-none d-lg-block"/>
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
          />
        </template>
        <dark-toggler-mobile/>

        <b-dropdown-divider
            class="d-lg-none"
        />

        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Kijelentkezés</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BIconArrowLeft,
  BNavItem,
  BTooltip,
  BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import DarkTogglerMobile from '@core/layouts/components/app-navbar/components/DarkTogglerMobile.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from 'vuex'
import useAppConfig from "@core/app-config/useAppConfig";
import {$themeConfig} from "@themeConfig";
import LanguageSelector from "@/layouts/components/LanguageSelector.vue";

export default {
  components: {
    LanguageSelector,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BIconArrowLeft,
    BNavItem,
    BTooltip,
    BImg,
    // Navbar Components
    DarkToggler,
    DarkTogglerMobile,
    SearchBar
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters([
      'userName',
      'userRole',
      'isLogged',
    ]),
  },
  setup() {
    const {skin, layoutType} = useAppConfig()

    // App Name
    const {appName, appLogoImage, appLogoImageDark, appLogoImageLight} = $themeConfig.app
    return {
      appName,
      appLogoImage,
      appLogoImageDark,
      appLogoImageLight,
      skin,
      layoutType
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$helpers.logout()
        this.$helpers.showSuccessToast('Sikeres kijelentkezés')
      })
    },
  }
}
</script>
