export default [
    {
        title: 'menu.dashboard',
        route: 'home',
        icon: 'HomeOutline',
    },
    {
        title: 'menu.countries',
        route: 'country-list',
        icon: 'FlagOutline',
        children: [
            {
                title: 'menu.list',
                route: 'countries',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-country',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'country',
        },
    },
    {
        title: 'menu.counties',
        route: 'county-list',
        icon: 'MapOutline',
        children: [
            {
                title: 'menu.list',
                route: 'counties',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-county',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'county',
        },
    },
    {
        title: 'menu.areaCodes',
        route: 'areaCode-list',
        icon: 'PhoneDialOutline',
        children: [
            {
                title: 'menu.list',
                route: 'areaCodes',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-areaCode',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'areaCode',
        },
    },
    {
        title: 'menu.phoneNumbers',
        route: 'phoneNumber-list',
        icon: 'PhoneLogOutline',
        children: [
            {
                title: 'menu.list',
                route: 'phoneNumbers',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-phoneNumber',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'phoneNumber',
        },
    },
    {
        title: 'menu.phoneNumberComments',
        route: 'phoneNumberComments',
        icon: 'CommentTextOutline',
    },
    {
        title: 'menu.pages',
        route: 'page-list',
        icon: 'PageLayoutBody',
        children: [
            {
                title: 'menu.list',
                route: 'pages',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-page',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'page',
        },
    },
    {
        title: 'menu.posts',
        route: 'post-list',
        icon: 'NoteOutline',
        children: [
            {
                title: 'menu.list',
                route: 'posts',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-post',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'post',
        },
    },
    {
        title: 'menu.categories',
        route: 'category-list',
        icon: 'FolderOutline',
        children: [
            {
                title: 'menu.list',
                route: 'categories',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-category',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'category',
        },
    },
    {
        title: 'menu.tags',
        route: 'tag-list',
        icon: 'TagOutline',
        children: [
            {
                title: 'menu.list',
                route: 'tags',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-tag',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'tag',
        },
    },
    {
        title: 'menu.users',
        route: 'user-list',
        icon: 'AccountOutline',
        children: [
            {
                title: 'menu.list',
                route: 'users',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-user',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'user',
        },
    },
    {
        title: 'menu.redirects',
        route: 'redirect-list',
        icon: 'ArrowDecision',
        children: [
            {
                title: 'menu.list',
                route: 'redirects',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-redirect',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'redirect',
        },
    },
    {
        title: 'menu.options',
        route: 'options',
        icon: 'CogOutline',
    },
]
